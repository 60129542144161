body {
  padding: 0% 8%;
}

.display-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.todo-item.completed .todo-content {
  color: gray;
  text-decoration: line-through;
}
